body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
    margin: 0;
    padding: 0;
}

body,
button,
input,
select,
textarea {
    font: 12px/1.5tahoma, arial, \5b8b\4f53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
}

address,
cite,
dfn,
em,
var {
    font-style: normal;
}

code,
kbd,
pre,
samp {
    font-family: couriernew, courier, monospace;
}

small {
    font-size: 12px;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

legend {
    color: #000;
}

fieldset,
img {
    border: 0;
}

button,
input,
select,
textarea {
    font-size: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none !important
}

.sec1-sec2-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(66%);
    bottom: 0;
    width: 7%;
    height: 42%;
    max-height: 290px;
    z-index: 1;
}

.sec2-sec3-img {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 7%;
    height: 48%;
    max-height: 460px;
    transform: translateX(-50%);
    z-index: 1;
}

.fix-header {
    width: 100%;
    background-color: #f2f2f3;
    position: fixed;
    top: 0;
    z-index: 2;
    height: 41px;
}

.fix-header .container-pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    width: 1120PX;
    overflow: hidden;
}

.fix-header .container-pc .nav {
    margin-left: 20px;
    margin-left: 70px;
}

.fix-header .container-pc .logo {
    width: 120px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url('./images/header-logo.png');
    background-size: 100% 100%;

}

.fix-header .container-pc .item {
    font-size: 16PX;
    color: #666;
    text-decoration: none;
    display: inline-block;
    padding: 0px 25px;
    font-weight: bold;
}

.fix-header .container-pc .item.hight-light {
    color: #1e2773;
}