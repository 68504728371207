html,body{
    height:100%;
    background: url("../App//images//start_default_background.jpg") no-repeat;
    background-size:cover;
}
#root {
    min-width: 0; 
}
*{
    padding: 0;
    margin: 0;
}
.content_main{
    width: 100%;
    height: 400px;
}
.posi_div{
    width: 60%;
    height: 55px;
    margin: auto;
    background: url('../App/images/android_icon.png');
    background-size:cover;
    display:none;
}
.posi_eng_div{
    width: 60%;
    height: 55px;
    margin: auto;
    background: url('../App/images/android_icon_eng.png');
    background-size:cover;
    margin-top: 40px;
}
.main_icon{
    width: 60%;
    height: 55px;
    margin: auto;
    background: url('../App/images/apple_icon_eng.png');
    background-size:cover;
    margin-top: 40px;
}
.description_span{
    font-size: 0.4125rem;
    color: white;
    line-height: 90px;
}